import * as React from "react"
import styled from "styled-components"
import { up } from "styled-breakpoints"

import Text from "../../components/text"
import Icon from "../../components/icon"
import { Link } from "../../components/link"
import { color, space, getBg } from "../../theme"

export type ItemProps = {
  icon: IconKeys
  title: string
  titleCopy?: string
  prices: {
    price: string
    copy: string
  }[]
  bg: ColorKeys
}

export type BlockProps = {
  title: string
  copy: string
  link: LinkType
  items: ItemProps[]
}
export type Props = {
  data: BlockProps
  options?: {}
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-items: stretch;
  margin: 0 auto;
  padding: 0 2rem;
  ${up("tablet")} {
    grid-template-columns: 704px;
  }
  ${up("desktop")} {
    grid-template-columns: 1216px;
  }
`

const Container = styled.div`
  display: grid;
  grid-template-columns: auto;
  justify-items: flex-start;
  grid-gap: ${space("normal")};
  padding: ${space("small")} ${space("xsmall")};
  ${up("desktop")} {
    padding: ${space("large")} 0;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, auto);
  }
`

const List = styled.div<{ size: number }>`
  grid-column: 1;
  grid-row: 4;
  align-self: stretch;
  justify-self: stretch;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(${p => p.size}, auto);
  grid-gap: ${space("small")};
  align-items: stretch;
  justify-items: stretch;
  ${up("tablet")} {
    grid-template-rows: repeat(${p => p.size}, 115px);
    grid-column: 1;
    grid-row: 4;
  }
  ${up("desktop")} {
    grid-column: 2 / 4;
    grid-row: 2 / 6;
  }
`
const ItemContainer = styled.div<{ bg: ColorKeys }>`
  display: grid;
  grid-template-columns: 40px 1fr;
  grid-template-rows: 1fr auto;
  align-items: flex-start;
  justify-items: flex-start;
  grid-gap: ${space("small")};
  background-color: ${getBg};
  padding: ${space("small")} ${space("small")};
  ${up("tablet")} {
    padding: ${space("small")} ${space("normal")};
    grid-row-gap: ${space("xsmall")};
    grid-column-gap: 2rem;

    grid-template-columns: 40px 1fr 2fr;
    grid-template-rows: 1fr;
    align-items: center;
  }
`
const TitleContainer = styled.div<{ hasCopy?: boolean }>`
  display: grid;
  grid-template-rows: ${p => (p.hasCopy ? `auto auto` : "auto")};
  grid-gap: 0.1rem;
  align-items: flex-start;
  justify-items: flex-start;
`
const PriceContainer = styled.div<{ size: number }>`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(${p => p.size}, 1fr);
  align-items: center;
  justify-items: flex-start;
  grid-column: 2;
  ${up("tablet")} {
    grid-column: 3;
  }
`
const PriceItem = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: ${space("xsmall")};
  align-items: flex-start;
  justify-items: flex-start;
  grid-column: 2;
  ${up("tablet")} {
    align-items: center;
  }
`

const PriceIconContainer = styled.div`
  grid-column: 1;
  grid-row: 1/2;

  width: 40px;
  height: 40px;
  display: grid;
  align-items: center;
  justify-items: center;
  background-color: ${color("white")};
  border-radius: 4px;
`

const PriceIcon = styled(Icon)`
  position: relative;
  top: ${p => (p.name === "arrow-down" ? -5 : p.name === "arrow-up" ? 5 : 0)}px;
`

const Title = styled(Text)`
  grid-column: 1;
  grid-row: 1;
  ${up("tablet")} {
    grid-column: 1;
    grid-row: 1;
  }
  ${up("desktop")} {
    grid-column: 1 / 4;
    grid-row: 1;
  }
`
const Copy = styled(Text)`
  border-top: 1px solid ${color("lightgrey")};
  padding-top: ${space("normal")};
  grid-column: 1;
  grid-row: 2;
  ${up("tablet")} {
    grid-column: 1;
    grid-row: 2;
  }
  ${up("desktop")} {
    max-width: 300px;
    grid-column: 1;
    grid-row: 2;
  }
`
const More = styled(Link)`
  grid-column: 1;
  grid-row: 3;
  ${up("tablet")} {
    grid-column: 1;
    grid-row: 3;
  }
  ${up("desktop")} {
    grid-column: 1;
    grid-row: 3;
  }
`

const Item: React.FC<ItemProps> = ({ bg, title, titleCopy, icon, prices }) => {
  return (
    <ItemContainer bg={bg}>
      <PriceIconContainer>
        <PriceIcon name={icon} color={bg} />
      </PriceIconContainer>
      <TitleContainer hasCopy={!!titleCopy}>
        <Text type="h4" color="white">
          {title}
        </Text>
        {titleCopy && (
          <Text mobileType="mobileBody" color="white">
            {titleCopy}
          </Text>
        )}
      </TitleContainer>
      <PriceContainer size={prices.length}>
        {prices.map(({ price, copy }, idx) => (
          <PriceItem key={`price_tags_${idx}`}>
            <Text type="h4" mobileType="body" color="white">
              {price}
            </Text>
            <Text color="white" mobileType="mobileBody">
              {copy}
            </Text>
          </PriceItem>
        ))}
      </PriceContainer>
    </ItemContainer>
  )
}

const Block1: React.FC<Props> = ({ data, options }) => {
  return (
    <Wrapper>
      <Container>
        <Title type="headline" color="black">
          {data.title}
        </Title>
        <Copy color="grey">{data.copy}</Copy>
        <More to={data.link.to}>{data.link.copy}</More>
        <List size={data.items.length}>
          {data.items.map((item, idx) => (
            <Item key={`list_item_${idx}`} {...item} />
          ))}
        </List>
      </Container>
    </Wrapper>
  )
}

export default Block1
