import React from "react"
import { Helmet } from "react-helmet"

import Pricing from "../blocks/Pricing"

const PricingPage = () => (
  <>
    <Helmet>
      <title>Paybase - Pricing</title>
      <meta property="og:title" content="Paybase - Pricing" />
    </Helmet>
    <Pricing
      data={{
        block1: {
          title: "Pricing",
          copy:
            "Paybase offers competitive pricing tailored to your business needs. See our standard pricing model or get in touch to find out about our tailored pricing options!",
          link: { to: "https://trustshare.co", copy: "Get in touch" },
          items: [
            {
              icon: "arrow-down",
              title: "Pay in",
              titleCopy: "with Card",
              prices: [{ price: "1.4% + 20p", copy: "Per transaction" }],
              bg: "blue",
            },
            {
              icon: "arrow-up",
              title: "Pay out",
              titleCopy: "with UK Faster Payments",
              prices: [{ price: "50p", copy: "Per transaction" }],
              bg: "red",
            },
            {
              icon: "check",
              title: "Due Diligence",
              prices: [
                { price: "40p", copy: "Per active user / month" },
                { price: "80p", copy: "Per active business / month" },
              ],
              bg: "darkgreen",
            },
            {
              icon: "lock",
              title: "Escrow",
              prices: [{ price: "0.25%", copy: "Per transaction" }],
              bg: "purple",
            },
          ],
        },
        block2: {
          title: "Earn and retain more revenue",
          ups: [
            "Retain users with instant end-to-end payments",
            "Strong financial crime prevention framework",
            "Configurable loyalty programmes",
            "Escrow functionality",
            "Customisable fee structures",
          ],
          downs: [
            "Users transact off your platform",
            "Cost of chargebacks & fraudulent transactions",
            "Buyers and sellers join rival platforms",
            "Checkout abandonment",
            "Third-party provider fees",
          ],
          copy:
            "On top of Paybase’s competitive pricing, our solution allows your business to earn and retain more revenue. Check out our capabilities for more information.",
          link: { to: "/what-we-offer", copy: "View all capabilities" },
        },
      }}
    />
  </>
)

export default PricingPage
