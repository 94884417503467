import * as React from "react"
import styled from "styled-components"

import Layout from "../../components/layout"

import Block1, { BlockProps } from "./pricing"
import Block2, { Block2Props } from "./earn"

export type Props = {
  data: {
    block1: BlockProps
    block2: Block2Props
  }
  options?: {}
}

const Container = styled.div`
  display: grid;
`

const Pricing: React.FC<Props> = ({ data, options }) => {
  return (
    <Layout>
      <Container>
        <Block1 data={data.block1} />
        <Block2 data={data.block2} />
      </Container>
    </Layout>
  )
}

export default Pricing
