import * as React from "react"
import styled from "styled-components"
import { up } from "styled-breakpoints"

import { color, space } from "../../theme"
import useBreakpoints from "../../utils/use-breakpoint"

import Text from "../../components/text"
import { Link } from "../../components/link"
import Icon from "../../components/icon"

export type Block2Props = {
  title: string
  ups: string[]
  downs: string[]
  copy: string
  link: LinkType & { icon?: IconKeys }
}
export type Props = {
  data: Block2Props
  options?: {}
}
const BreakpointContext = React.createContext("desktop")

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-items: stretch;
  padding: ${space("large")} ${space("normal")};
  margin: 0 auto;
  border-top: 1px solid ${color("lightgrey")};
  ${up("tablet")} {
    padding: ${space("large")} 0;
    grid-template-columns: 768px;
  }
  ${up("desktop")} {
    grid-template-columns: 1280px;
  }
`

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, auto);
  grid-gap: ${space("normal")};
  justify-items: center;
`

const Copy = styled(Text)`
  max-width: 400px;
`
const ListContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${space("small")};
  ${up("desktop")} {
    grid-template-columns: 1fr 1fr;
  }
`
const List = styled.div<{ size: number }>`
  display: grid;
  grid-template-rows: repeat(${p => p.size + 1}, auto);
`

const RowArrow = styled.div<{ isUp?: boolean }>`
  min-height: 50px;
  align-self: stretch;
  display: grid;
  align-items: center;
  justify-items: center;
  background-color: ${p => color(p.isUp ? "green" : "rose")};
`

const RowContainer = styled.div<{ isUp?: boolean }>`
  display: grid;
  grid-template-columns: ${p => (p.isUp ? "50px 1fr" : "1fr 50px")};
  grid-gap: ${space("small")};
  align-items: center;
  justify-items: stretch;
  &:first-child {
    ${RowArrow} {
      border-radius: 6px 6px 0 0;
    }
  }
  &:last-child {
    ${RowArrow} {
      border-radius: 0 0 6px 6px;
    }
  }
  ${Text} {
    padding: 0.3rem;
  }
  ${up("tablet")} {
    height: 50px;
  }
  ${up("desktop")} {
    height: 80px;
    grid-template-columns: ${p => (p.isUp ? "194px 1fr" : "1fr 194px")};
  }
`
const RowLineContainer = styled.div<{ isUp?: boolean }>`
  display: grid;
  grid-template-columns: ${p => (p.isUp ? "50px 1fr" : "1fr 50px")};
  align-items: center;
  justify-items: stretch;
  ${up("desktop")} {
    grid-template-columns: ${p => (p.isUp ? "194px 1fr" : "1fr 194px")};
  }
`

const RowLineBG = styled.div<{ color: string; opacity?: number }>`
  align-self: stretch;
  height: 1px;
  opacity: ${p => p.opacity || null};
  background-color: ${p => p.color};
`

const RowLine: React.FC<{ isUp?: boolean }> = ({ isUp }) => {
  const breakpoint = React.useContext(BreakpointContext)
  const isD = isUp || ["mobile", "tablet"].includes(breakpoint)
  const content = [
    <RowLineBG key={`1_row_line`} color="#d1cce1" />,
    <RowLineBG
      key={`2_row_line`}
      color={isUp ? "#018956" : "#ff0042"}
      opacity={0.6}
    />,
  ]
  return (
    <RowLineContainer isUp={isD}>
      {isD ? content.reverse() : content}
    </RowLineContainer>
  )
}

const Row: React.FC<{ isUp?: boolean }> = ({ children, isUp }) => {
  const breakpoint = React.useContext(BreakpointContext)
  const isD = isUp || ["mobile", "tablet"].includes(breakpoint)
  const content = [
    children,
    <RowArrow key={`1_row_data`} isUp={isUp}>
      <Icon
        size={["mobile", "tablet"].includes(breakpoint) ? "xsmall" : "normal"}
        name={isUp ? "arrow-up" : "arrow-down"}
        color={isUp ? "darkgreen" : "white"}
      />
    </RowArrow>,
  ]
  return (
    <RowContainer isUp={isD}>{isD ? content.reverse() : content}</RowContainer>
  )
}

const Block2: React.FC<Props> = ({ data, options }) => {
  const breakpoint = useBreakpoints({
    config: { mobile: 0, tablet: 768, desktop: 1280 },
    defaultValue: "unknown",
  })
  const content = [
    <List key={`list_earn_1`} size={data.downs.length}>
      <Row>
        <Text type="h4" color="rose">
          Without Paybase
        </Text>
      </Row>
      <RowLine />
      {data.downs.map((down, idx) => [
        <Row key={`row_earn_down_${idx}`}>
          <Text>{down}</Text>
        </Row>,
        <RowLine key={`row_earn_down_line_${idx}`} />,
      ])}
    </List>,
    <List key={`list_earn_2`} size={data.ups.length}>
      <Row isUp>
        <Text type="h4" color="darkgreen">
          With Paybase
        </Text>
      </Row>
      <RowLine isUp />
      {data.ups.map((up, idx) => [
        <Row isUp key={`row_earn_up_${idx}`}>
          <Text>{up}</Text>
        </Row>,
        <RowLine key={`row_earn_up_line_${idx}`} isUp />,
      ])}
    </List>,
  ]

  return (
    <BreakpointContext.Provider value={breakpoint}>
      <Wrapper>
        <Container>
          <Text type="headline" color="black">
            {data.title}
          </Text>
          <ListContainer>
            {["mobile", "tablet"].includes(breakpoint)
              ? content.reverse()
              : breakpoint === "unknown"
              ? null
              : content}
          </ListContainer>
          <Copy align="center" color="grey">
            {data.copy}
          </Copy>
          <Link to={data.link.to}>{data.link.copy}</Link>
        </Container>
      </Wrapper>
    </BreakpointContext.Provider>
  )
}

export default Block2
